<template>
  <div>
    <div class="home_banner">
      <swiper
        ref="mySwiper"
        :slides-per-view="1"
        :space-between="0"
        :speed="1000"
        :effect="'fade'"
        @slideChange="onSlideChange"
      >
        <swiper-slide>
          <div v-for="(item,index) in banner.children" :key="index" :style="{ backgroundImage: `url(${baseUrl + item.iconUrl})` }" class="banner" />
        </swiper-slide>
      </swiper>
    </div>
    <div v-for="(item,index) in contentList" :key="index">
      <div v-if="item.flag === '11'" class="introduction">
        <div class="home_content">
          <div class="left">
            <p class="home_title">{{ item.shortTitle }}</p>
            <p class="home_sub">{{ item.title }}</p>
            <p class="detail">{{ item.remark }}</p>
            <div class="info">
              <ul>
                <li v-for="(cItem,cIndex) in item.children" :key="cIndex">
                  <div>
                    <p class="num_p">
                      <transition :key="cIndex" name="text">
                        <p v-show="tranText" class="num">{{ cItem.remark }}</p>
                      </transition>
                      {{ cItem.shortTitle }}
                    </p>
                    <p class="sub">{{ cItem.title }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="more" @click="moreNew(124)">了解更多</div>
          </div>
          <img :src="baseUrl + item.iconUrl" alt="">
        </div>
      </div>
      <div v-if="item.flag==='6'" class="area">
        <div class="home_content">
          <div class="head">
            <div class="left">
              <p class="home_title">{{ item.shortTitle }}</p>
              <p class="home_sub">{{ item.title }}</p>
            </div>
            <p class="right">{{ item.remark }}</p>
          </div>
          <transition name="area">
            <ul v-show="tranArea">
              <li v-for="(cItem,cIndex) in item.children" :key="cIndex" @click="productService(cItem.url,cItem.urlType)">
                <div :class="`area_${cIndex + 1}`" class="area_c">
                  <p class="title">{{ cItem.title }}</p>
                  <div class="block" />
                  <p>{{ cItem.remark }}</p>
                </div>
              </li>
            </ul>
          </transition>
        </div>
      </div>
      <div v-if="item.flag=== '15' && item.id === 978" class="new">
        <div class="home_content">
          <div class="head">
            <div class="left">
              <p class="home_title">{{ item.shortTitle }}</p>
              <p class="home_sub">{{ item.title }}</p>
            </div>
            <p class="right" @click="moreNew(135)">了解更多</p>
          </div>
          <div class="big_content">
            <transition name="left">
              <div v-show="tranNewPic" class="img_box">
                <img v-for="(nItem,nIndex) in newImg" v-show="newIndex === nIndex" :key="nIndex" :src="nItem" alt="" @click="detailImgArchieve(nIndex, 145)">
              </div>
            </transition>
            <transition name="right">
              <ul v-show="tranNewList">
                <li v-for="(aItem,aIndex) in archieveList" :key="aIndex" @mouseenter="newsListHover(aIndex)" @click="detailArchieve(aItem.id, 145)">
                  <div class="head">
                    <p class="title">{{ aItem.title }}</p>
                    <p class="date">{{ aItem.publishDate.slice(0,10) }}</p>
                  </div>
                  <p class="detail">{{ aItem.remark }}</p>
                </li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
      <div v-if="item.flag=== '15' && item.id === 1100" class="new clique">
        <div class="home_content">
          <div class="head">
            <div class="left">
              <p class="home_title">{{ item.shortTitle }}</p>
              <p class="home_sub">{{ item.title }}</p>
            </div>
            <p class="right" @click="moreClique">了解更多</p>
          </div>
          <div class="big_content">
            <transition name="left">
              <div v-show="tranCliquePic" class="img_box">
                <img v-for="(cItem,cIndex) in cliqueImg" v-show="cliqueIndex === cIndex" :key="cIndex" :src="cItem" alt="" @click="detailImgClique(cIndex, 157)">
              </div>
            </transition>
            <transition name="right">
              <ul v-show="tranCliqueList">
                <li v-for="(aItem,aIndex) in cliqueList" :key="aIndex" @mouseenter="cliqueListHover(aIndex)" @click="detailArchieve(aItem.id, 157)">
                  <div class="head">
                    <p class="title">{{ aItem.title }}</p>
                    <p class="date">{{ aItem.publishDate.slice(0,10) }}</p>
                  </div>
                  <p class="detail">{{ aItem.remark }}</p>
                </li>
              </ul>
            </transition>
          </div>
        </div>
      </div>
      <div v-if="item.flag === '16'" class="text_list">
        <div class="home_content">
          <transition name="left">
            <div v-show="tranStock" class="stock">
              <img class="logo" src="../../statics/images/home/xinli_s_logo.png" alt="">
              <p class="tit">股票代码：600318</p>
              <div class="block" :class="{'red': stockInc > 0, 'green': stockInc < 0 }">
                <p>{{ stockPrice }}</p>
                <div class="dea">
                  <p>{{ stockInc }}</p>
                  <p>{{ stockPercent }}%</p>
                </div>
              </div>
            </div>
          </transition>
          <transition name="right">
            <div v-show="tranNotice" class="notice">
              <div class="head">
                <div class="left">
                  <p class="home_title">{{ item.shortTitle }}</p>
                  <p class="home_sub">{{ item.title }}</p>
                </div>
                <p class="right" @click="moreNotice">了解更多</p>
              </div>
              <div class="list">
                <ul v-if="annGoverList">
                  <li v-for="nItem in annGoverList" :key="nItem.id" @click="detailArchieve(nItem.id,136)">
                    <p class="text">{{ nItem.title }}</p>
                    <p class="date">{{ nItem.publishDate }}</p>
                  </li>
                </ul>
                <div v-else class="no_info">暂无数据</div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div
      v-if="thedivShow"
      id="thediv"
      ref="thediv"
      style="position: fixed; z-index: 111; left: 0; top: 0"
      @mouseover="clearFdAd"
      @mouseout="starFdAd"
    >
      <div
        style="
        cursor: pointer;
        text-align: right;
        font-size: 12px;
        color: #999999;
      "
        @click="thedivShow = false"
      >
        关闭
      </div>
      <img :src="advertImg" alt="">
    </div>
  </div>
</template>
<script>
import { getMainContent, getArchiveListByMenuId, stockPrice, queryAdvert } from '@/plugin/api'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
var interval
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      banner: {},
      contentList: [],
      archieveList: [],
      cliqueList: [],
      cliqueIndex: 0,
      cliqueImg: [],
      baseUrl: '/website',
      annGoverList: [],
      newIndex: 0,
      newImg: [],
      stockPrice: 0,
      stockInc: 0,
      riseFall: -1,
      // 动画效果参数
      tranText: false,
      tranArea: false,
      tranNewPic: false,
      tranNewList: false,
      tranCliquePic: false,
      tranCliqueList: false,
      tranStock: false,
      tranNotice: false,
      // 广告弹窗参数
      xPos: 0,
      yPos: 0,
      xin: true,
      yin: true,
      step: 1,
      delay: 18,
      height: 0,
      Hoffset: 0,
      Woffset: 0,
      yon: 0,
      xon: 0,
      pause: true,
      thedivShow: false,
      advertImg: null
    }
  },
  mounted() {
    this.getInfo()
    window.scrollTo({
      top: 0
    })
    interval = setInterval(this.changePos, this.delay)
    setInterval(this.getStock(), 30000)
    const dWidth = document.documentElement.clientWidth
    if (dWidth < 1024) {
      this.tranText = true
      this.tranArea = true
      this.tranNewPic = true
      this.tranNewList = true
      this.tranCliquePic = true
      this.tranCliqueList = true
      this.tranStock = true
      this.tranNotice = true
    } else {
      window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    }
  },
  methods: {
    changePos() {
      if (!this.$refs.thediv) {
        return
      }
      const width = document.documentElement.clientWidth
      const height = document.documentElement.clientHeight
      this.Hoffset = this.$refs.thediv.offsetHeight // 获取元素高度
      this.Woffset = this.$refs.thediv.offsetWidth

      // 滚动部分跟随屏幕滚动
      // this.$refs.thediv.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
      // this.$refs.thediv.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px";

      // 滚动部分不随屏幕滚动
      this.$refs.thediv.style.left =
        this.xPos + document.body.scrollLeft + 'px'
      this.$refs.thediv.style.top = this.yPos + document.body.scrollTop + 'px'

      if (this.yon) {
        this.yPos = this.yPos + this.step
      } else {
        this.yPos = this.yPos - this.step
      }
      if (this.yPos < 0) {
        this.yon = 1
        this.yPos = 0
      }
      if (this.yPos >= height - this.Hoffset) {
        this.yon = 0
        this.yPos = height - this.Hoffset
      }

      if (this.xon) {
        this.xPos = this.xPos + this.step
      } else {
        this.xPos = this.xPos - this.step
      }
      if (this.xPos < 0) {
        this.xon = 1
        this.xPos = 0
      }
      if (this.xPos >= width - this.Woffset) {
        this.xon = 0
        this.xPos = width - this.Woffset
      }
    },
    clearFdAd() {
      clearInterval(interval)
    },
    starFdAd() {
      this.clearFdAd()
      interval = setInterval(this.changePos, this.delay)
    },
    newsListHover(index) {
      this.newIndex = index
    },
    cliqueListHover(index) {
      this.cliqueIndex = index
    },
    detailArchieve(id, menuId) {
      const routeData = this.$router.resolve({
        path: '/archieveDetail', query: { menuId, id: id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/archieveDetail', query: { menuId, id }})
    },
    detailImgArchieve(index, menuId) {
      const id = this.archieveList[index].id
      const routeData = this.$router.resolve({
        path: '/archieveDetail', query: { menuId, id: id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/archieveDetail', query: { menuId, id }})
    },
    detailImgClique(index, menuId) {
      const id = this.cliqueList[index].id
      const routeData = this.$router.resolve({
        path: '/archieveDetail', query: { menuId, id: id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/archieveDetail', query: { menuId, id }})
    },
    moreNew(url) {
      this.$router.push({
        path: '/xljrEnteringXinLi',
        query: {
          urlType: '2',
          url,
          fUrlType: '2',
          fUrl: '110'
        }
      })
    },
    moreClique() {
      this.$router.push({
        path: '/enterpriseBuilding',
        query: {
          urlType: '2',
          url: '151',
          fUrlType: '2'
        }
      })
    },
    moreNotice() {
      this.$router.push({
        path: '/xljrInvestorRelations',
        query: {
          urlType: '2',
          url: '141',
          fUrlType: '2',
          fUrl: '87'
        }
      })
    },
    companyDetail() {
      this.$router.push({ path: '/companyView' })
    },
    productService(url, urlType) {
      this.$router.push({ path: '/xljrProductServices', query: { url, urlType }})
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 400) {
        this.tranText = true
      }
      if (scrollTop > 1300) {
        this.tranArea = true
      }
      if (scrollTop > 1700) {
        this.tranNewPic = true
        this.tranNewList = true
      }
      if (scrollTop > 2100) {
        this.tranCliquePic = true
        this.tranCliqueList = true
      }
      if (scrollTop > 2600) {
        this.tranStock = true
        this.tranNotice = true
      }
    },
    getInfo() {
      getMainContent({ tenantId: 'xljr', url: '136', urlType: 2 }).then(res => {
        const arr = res.data.data.contentMainList[0].contentItemTreeList
        this.contentList = arr
        this.banner = arr.find(item => {
          return item.id === 965
        })
      })
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 145, pageNum: 1, pageSize: 3 }).then(res => {
        this.archieveList = res.data.data.archiveList
        this.archieveList.forEach(item => {
          const s = item.iconUrl ? '/website' + item.iconUrl : '../../statics/images/b_logo.png'
          this.newImg.push(s)
        })
      })
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 136, pageNum: 1, pageSize: 10 }).then(res => {
        this.annGoverList = res.data.data.archiveList
      })
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 157, pageNum: 1, pageSize: 3 }).then(res => {
        this.cliqueList = res.data.data.archiveList
        this.cliqueList.forEach(item => {
          const s = item.iconUrl ? '/website' + item.iconUrl : '../../statics/images/b_logo.png'
          this.cliqueImg.push(s)
        })
      })
      queryAdvert({ tenantId: 'xljr' }).then(res => {
        if (res.data.data) {
          this.advertImg = res.data.data.iconurl ? this.baseUrl + res.data.data.iconurl : null
        }
        this.thedivShow = !!this.advertImg
      })
      this.getStock()
    },
    getStock() {
      stockPrice().then(res => {
        if (res.status === 200) {
          const sArr = res.data.split('~')
          this.stockPrice = sArr[3]
          this.stockInc = Number(sArr[31])
          this.stockPercent = sArr[32]
        }
      })
    },
    onSlideChange(swiper) {
    }
  }
}
</script>

<style lang="scss" scoped>
#thediv {
  z-index: 100;
  position: absolute;
  top: 43px;
  left: 2px;
  img{
    max-width: 400px;
  }
}
.text-enter-active{
  animation: bounce 1s ease;
}
.area-enter-active{
  animation: fadeInUp 1s ease;
}
.left-enter-active{
  animation: fadeInLeft 1s ease;
}
.right-enter-active{
  animation: fadeInRight 1s ease;
}
.text_list{
  background-color: #EAF1F5;
  padding: 50px 0;
  .home_content{
    .head{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 30px;
      .right{
        cursor: pointer;
        color: #155FAE;
        font-size: 14px;
      }
    }
      display: flex;
      justify-content: space-between;
      .stock{
        display: flex;
    flex-direction: column;
    align-items: center;
        padding: 20px 20px 20px 20px;
        background-image: url(../assets/images/home/stock_bg.png);
        background-size: cover;
        margin-right: 70px;
        text-align: center;
        color: #fff;
        .tit{
          font-size: 20px;
          margin-bottom: 30px;
        }
        img{
          width: 220px;
          margin-top: 70px;
          margin-bottom: 30px;
        }
        .block{
          width: 260px;
          background-color: #fff;
          color: #999999;
          font-size: 70px;
          padding: 15px 0;
          &.red{
            color: #FF0000;
          }
          &.green{
            color: #008000;
          }
          .dea{
            margin: 0 65px;
            font-size: 20px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
      .notice{
        flex: 1;
        .list{
          margin-top: 25px;
          li{
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            color: #101010;
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400;
            .text{
              flex: 0 0 650px;
            }
            &:hover{
                color: #155FAE;
            }
          }
        }
      }
    }
  }
.home_banner{
  width: 100%;
  height: 700px;
  background-color: #fff;
  position: relative;
  .swiper-container{
    height: 100%;
  }
  .banner{
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    img{
      width: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
    }
  }
  .banner_content{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    color: #fff;
    .content{
      width: 1200px;
      min-width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .banner_content_text{
        height: 211px;
      }
      .title{
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 12px;
      }
      .sub{
        font-size: 24px;
        margin-bottom: 18px;
      }
      ul{
        margin-bottom: 35px;
        li{
          display: flex;
          align-items: center;
          .round{
            position: relative;
            top: -2px;
            margin-right: 10px;
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 10px;
            background-color: #6C6C6C;
          }
        }
      }
      .detail_box{
        height: 40px;
        .detail{
          display: inline-block;
          padding: 10px 29px;
          color: #fff;
          font-size: 14px;
          border-radius: 3px;
          background: linear-gradient(#00A59E, #2B9BF7);
        }
      }
    }

  }
}
.area{
  min-height: 580px;
  background-color: #fff;
  padding: 125px 0;
  .home_content{
    .head{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 68px;
      .right{
        color: #155FAE;
        font-size: 14px;
      }
    }
    ul{
      display: flex;
      justify-content: space-between;
      li{
        cursor: pointer;
        position: relative;
        .area_c{
          width: 185px;
          height: 415px;
          color: #F7F7F7;
          font-weight: 700;
          .logo{
            width: 100%;
            height: 100%;
          }
          p{
            margin-left: 30px;
            margin-bottom: 10px;
          }
          .title{
            padding-top: 27px;
            margin-bottom: 40px;
          }
          .block{
            margin-left: 30px;
            width: 10px;
            height: 5px;
            background-color: #fff;
            margin-bottom: 15px;
          }
        }
        .area_1{
          background-image: url(../assets/images/home/area_1.jpg);
        }
        .area_2{
          background-image: url(../assets/images/home/area_2.jpg);
        }
        .area_3{
          background-image: url(../assets/images/home/area_3.jpg);
        }
        .area_4{
          background-image: url(../assets/images/home/area_4.jpg);
        }
        .area_5{
          background-image: url(../assets/images/home/area_5.jpg);
        }
        .area_6{
          background-image: url(../assets/images/home/area_6.jpg);
        }
        .area_1:hover{
          background-image: url(../assets/images/home/area_1_1.jpg);
        }
        .area_2:hover{
          background-image: url(../assets/images/home/area_2_1.jpg);
        }
        .area_3:hover{
          background-image: url(../assets/images/home/area_3_1.jpg);
        }
        .area_4:hover{
          background-image: url(../assets/images/home/area_4_1.jpg);
        }
        .area_5:hover{
          background-image: url(../assets/images/home/area_5_1.jpg);
        }
        .area_6:hover{
          background-image: url(../assets/images/home/area_6_1.jpg);
        }
      }
    }
  }
}
.new{
  background-color: #EAF1F5;
  padding: 125px 0 50px 0;
  .home_content{
    &>.head{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 68px;
      .right{
        color: #155FAE;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .big_content{
      display: flex;
      margin-bottom: 60px;
      .img_box{
        flex: 0 0 530px;
        margin: 20px 40px 20px 0;
        &>img{
          cursor: pointer;
          width: 100%;
          height: 340px;
        }
      }
      ul{
        li{
          cursor: pointer;
          border-bottom: 1px solid #CCCCCC;
          padding: 27px 0;
          &:last-child{
            border: none;
          }
          &:hover{
            .head{
              .title{
              color: #0A66B2;
            }
            }
            .detail{
              color: #0A66B2;
            }
          }
          .head{
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            .title{
              color: #333;
              font-size: 18px;
              width: 550px;
              white-space:nowrap;
              text-overflow:ellipsis;
              overflow:hidden;
            }
            .date{
            color: #999999;
              font-size: 14px;
            }
          }
          .detail{
            color: #999999;
            font-size: 14px;

          text-align: justify;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          }
        }
      }
    }
    .small_content{
      ul{
        display: flex;
        .new_item{
          cursor: pointer;
          display: flex;
          padding-right: 100px;
          &>img{
            width: 200px;
            height: 120px;
            margin-right: 20px;
          }
          .right{
            .head{
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-bottom: 10px;
              .title{
                color: #333;
                font-size: 16px;
                width: 180px;
                height: 44px;
                word-break: break-all;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .date{
                color: #666;
                font-size: 14px;
              }
            }
            .detail{
              width: 280px;
              height: 40px;
              color: #666;
              font-size: 14px;
              margin-bottom: 20px;
              word-break: break-all;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .label_list{
              display: flex;
              li{
                border: 1px solid #155FAE;
                color: #155FAE;
                font-size: 14px;
                padding: 5px;
                border-radius: 4px;
                margin: 0 10px 10px 0;
              }
            }
          }
        }
      }
    }
  }
}
.clique{
  background-color: #fff;
}
</style>
